import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Environment } from '@playq/octopus-common';

import { PopoverButton } from '/shared/PopoverButton';
import { EnvironmentStatus } from '/shared/Environment/EnvironmentStatus';
import { EnvironmentForm } from '/shared/Environment/EnvironmentForm';

import { EnvironmentEntitiesSerialized, IEnvironmentRegisterProps } from '../types';

const defaultEnvs = { live: [], sandbox: [] };

export const EnvironmentRegister: FC<IEnvironmentRegisterProps> = (props) => {
  const {
    envs: propsEnvs,
    envProcessing,
    entityId,
    entityProcessing,
    withoutPopover = false,
    onPromote,
    onDemote,
    queryEnvs,
    enableQueryEnvs,
  } = props;

  const [envs, setEnvs] = useState<EnvironmentEntitiesSerialized>(defaultEnvs);

  // passing callback to deps can cause too many re-renders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cachedEnvs = useMemo(() => queryEnvs?.(), []);

  useEffect(() => {
    if (queryEnvs !== undefined) {
      enableQueryEnvs?.(cachedEnvs === undefined);
    }
    // passing callback to deps can cause too many re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedEnvs]);

  useEffect(() => {
    setEnvs(propsEnvs);
  }, [propsEnvs]);

  const handlePromote = useCallback(
    (env: Environment) => {
      onPromote(env);
      const key = env === Environment.Sandbox ? 'sandbox' : 'live';
      setEnvs((prevEnvs) => ({ ...prevEnvs, [key]: prevEnvs[key].concat(entityId) }));
    },
    [entityId, onPromote]
  );

  const handleDemote = useCallback(
    (env: Environment) => {
      onDemote(env);
      const key = env === Environment.Sandbox ? 'sandbox' : 'live';
      setEnvs((prevEnvs) => ({
        ...prevEnvs,
        [key]: prevEnvs[key].filter((promotedEntityId) => promotedEntityId !== entityId),
      }));
    },
    [entityId, onDemote]
  );

  const environmentForm = (
    <EnvironmentForm
      {...props}
      envs={envs}
      envProcessing={entityProcessing ? envProcessing : []}
      id={entityId}
      onPromote={handlePromote}
      onDemote={handleDemote}
    />
  );

  const environmentButton = <EnvironmentStatus envs={envs} id={entityId} />;

  if (withoutPopover) {
    return environmentForm;
  }

  return (
    <PopoverButton
      canClose={envProcessing.length === 0}
      button={environmentButton}
      popoverProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
    >
      {environmentForm}
    </PopoverButton>
  );
};
