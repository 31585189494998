import { styled } from '@mui/material';

import { LabelThemed } from '/shared/LabelThemed';

export const StatusButton = styled('button')`
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
  user-select: none;
  outline: none;
  padding-left: 0;
  row-gap: 3px;
  justify-content: space-between;
`;

export const EnvLabel = styled(LabelThemed)`
  margin: 0;
  font-size: 13px;
  padding: 2px 4px;
  min-width: 59px;

  ${({ theme, env }) =>
    !env &&
    `
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      color: ${theme.palette.text.primary};
      font-weight: 400;
  `}
`;
