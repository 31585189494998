import { Checkbox, styled } from '@mui/material';

import { Environment } from '@playq/octopus-common';

const CHECKBOX_SIZE = 18;
const ENV_LABEL_MIN_WIDTH = 54;

export const FormWrapper = styled('div')`
  display: grid;
  grid-template-columns: minmax(${ENV_LABEL_MIN_WIDTH}px, 1fr) ${CHECKBOX_SIZE}px;
  grid-column-gap: ${(props) => props.theme.spacing(2)};
  align-items: center;
  padding: ${(props) => props.theme.spacing(1)};
  grid-template-rows: repeat(2, ${CHECKBOX_SIZE}px);
  grid-row-gap: ${(props) => props.theme.spacing(2)};
`;

export const SandboxLabel = styled('span')`
  color: ${({ theme }) => theme.palette.promotion.sandbox};
`;

export const LiveLabel = styled('span')`
  color: ${({ theme }) => theme.palette.promotion.live};
`;

export const EnvCheckbox = styled(Checkbox)<{ type: Environment }>`
  height: ${CHECKBOX_SIZE}px;

  &.Mui-checked {
    color: ${({ theme, type }) =>
      type === Environment.Sandbox ? theme.palette.promotion.sandbox : theme.palette.promotion.live};
  }

  &:disabled {
    pointer-events: auto;
  }
`;

export const DotsLoading = styled('span')({
  color: 'inherit',
  '&::after': {
    content: "' .'", // This keeps the ::after content
    animation: 'dotsAnimation 1s steps(5, end) infinite', // Apply the animation
  },
  '@keyframes dotsAnimation': {
    '0%, 20%': {
      color: 'rgba(0, 0, 0, 0)',
      textShadow: '0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0)',
    },
    '40%': {
      color: 'currentColor',
      textShadow: '0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0)',
    },
    '60%': {
      textShadow: '0.25em 0 0 currentColor, 0.5em 0 0 rgba(0, 0, 0, 0)',
    },
    '80%, 100%': {
      textShadow: '0.25em 0 0 currentColor, 0.5em 0 0 currentColor',
    },
  },
});
