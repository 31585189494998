import { FC } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';

import { FileID, FileRevisionID } from '@playq/octopus2-files';

import { nativeFilePicker$ } from '/helpers';
import { Dropzone } from '/shared/Dropzone';

import { ActionButton, Content } from './styles';

export interface IFilesPickDialogProps extends Omit<DialogProps, 'children'> {
  fileRevisionID?: FileRevisionID;
  retryFileID?: FileID;
  onFilesRead: (fs: File[], fileRevisionID?: FileRevisionID, fileID?: FileID) => void;
  onClose: () => void;
}

export const FilesPickDialog: FC<IFilesPickDialogProps> = (props) => {
  const { open, onFilesRead, onClose, fullWidth = true, maxWidth = 'lg', fileRevisionID, retryFileID } = props;

  const handleFilePick = () => {
    nativeFilePicker$(true).subscribe((fs: File[]) => onFilesRead(fs, fileRevisionID, retryFileID));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogContent
        sx={{
          height: '500px',
          padding: '24px',
        }}
      >
        <Dropzone onFilesRead={onFilesRead} border={true}>
          <Content>
            <Typography
              variant={'h4'}
              fontWeight={'fontWeightMedium'}
              sx={{ color: ({ palette }) => palette.secondary.main }}
            >
              Drop files here
            </Typography>
            <p>Or, if you prefer...</p>

            <ActionButton variant='outlined' color='primary' onClick={handleFilePick} data-testid='button-select-file'>
              Select files from you computer
            </ActionButton>
          </Content>
        </Dropzone>
      </DialogContent>
    </Dialog>
  );
};
