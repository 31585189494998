import { Button, css, styled } from '@mui/material';

import { ThemeMode } from '/common/models';

export const Content = styled('div')`
  display: grid;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;

export const ActionButton = styled(Button)`
  margin-top: 10px;

  ${({ theme }) =>
    theme.palette.mode === ThemeMode.Dark &&
    css`
      color: ${theme.palette.text.primary};
      border-color: ${theme.palette.text.secondary};

      &:hover {
        border-color: ${theme.palette.text.primary};
        background-color: ${theme.palette.action.hover};
      }
    `}
`;
