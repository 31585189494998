import { FC, useCallback, useEffect, useId, useState } from 'react';
import { DateRangePicker as ReactDatesPicker, FocusedInputShape } from 'react-dates';
import moment, { Moment } from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { DateTime, DateTimeFormatOptions } from 'luxon';

import { MonthYearPicker } from './MonthYearPicker';
import { DateRange, IAdditionalPickerFocus, IDateRangeProps, ITimePickerValue } from './types';
import { DateRangeLabel, DateRangePickerContainer, TimePickerStyled, TimePickerWrapper } from './styles';
import { TimePresets } from './TimePresets';

const getStartOrEndDate = (date: Date | null) => (date ? moment(date) : null);

const defaultMinDate = new Date(0);
const defaultMaxDate = new Date();
defaultMaxDate.setFullYear(2033);
defaultMaxDate.setHours(23, 59, 59, 9999);
const defaultTimePickerStart = new Date();
defaultTimePickerStart.setHours(0, 0, 0);

export const DateRangePicker: FC<IDateRangeProps> = (props) => {
  const {
    dateRange: propsDateRange,
    onDateRangeChange,
    onClose,
    isMaterialStyled,
    label,
    anchorDirection = 'right',
    withTimePicker = true,
    size = 'small',
    singleMonth = false,
    error,
    minDateTime = defaultMinDate,
    maxDateTime = defaultMaxDate,
    openDirection = 'down',
    displayFormat,
    withTimePresets = !singleMonth,
    renderInModal = false,
    disabled = false,
    ...rest
  } = props;

  const [dateRange, setDateRange] = useState<DateRange>(propsDateRange);
  const [shouldUpdateMonths, setShouldUpdateMonths] = useState<boolean>(false);
  const [pickerHeight, setPickerHeight] = useState<number>(0);
  const [openAdditionalPicker, setOpenAdditionalPicker] = useState<IAdditionalPickerFocus>({
    open: false,
    position: null,
  });
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [timePickerValues, setTimePickerValues] = useState<ITimePickerValue>({
    start: DateTime.fromJSDate(propsDateRange[0] || defaultTimePickerStart),
    end: DateTime.fromJSDate(propsDateRange[1] || defaultMaxDate),
  });

  const startDateId = useId();
  const endDateId = useId();

  const handleFormat = useCallback(() => {
    if (displayFormat === undefined) {
      return 'DD MMM YYYY HH:mm';
    }

    if (typeof displayFormat === 'string') {
      return displayFormat;
    }
    return displayFormat();
  }, [displayFormat]);

  useEffect(() => {
    setDateRange(propsDateRange);
  }, [propsDateRange]);

  useEffect(
    function UpdateInnerDateRange() {
      const unselectedRange = dateRange.some((date) => date === null);
      if (unselectedRange) {
        return;
      }
      setTimePickerValues({
        start: DateTime.fromJSDate(propsDateRange[0] || defaultTimePickerStart),
        end: DateTime.fromJSDate(propsDateRange[1] || defaultMaxDate),
      });
    },
    // In this useEffect we need should update only if propsDateRange was changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [propsDateRange]
  );

  const transitionContainerStylesheet = document.createElement('style');
  document.head.appendChild(transitionContainerStylesheet);

  const calcWeeksInMonth = (date: moment.Moment) => {
    const dateFirst = moment(date).date(1);
    const dateLast = moment(date).date(date.daysInMonth());
    const startWeek = dateFirst.week();
    const endWeek = dateLast.week();
    if (endWeek < startWeek) {
      return dateFirst.weeksInYear() - startWeek + 1 + endWeek;
    } else {
      return endWeek - startWeek + 1;
    }
  };

  const changeMonthHandler = useCallback(
    (date: moment.Moment) => {
      const cellHeight = 40;
      const paddingWithTime = 165;
      const basePadding = 100;
      const padding = withTimePicker ? paddingWithTime : basePadding;

      if (singleMonth) {
        const heightSingleMonth = cellHeight * calcWeeksInMonth(date) + padding;
        transitionContainerStylesheet.innerHTML = `
    .DayPicker_transitionContainer {
      height: ${heightSingleMonth}px !important;
    }
  `;
        setPickerHeight(heightSingleMonth);
        return;
      }

      const heightDoubleMonth =
        cellHeight * Math.max(calcWeeksInMonth(date), calcWeeksInMonth(moment(date.startOf('M').add(1, 'M')))) +
        padding;
      transitionContainerStylesheet.innerHTML = `
    .DayPicker_transitionContainer {
      height: ${heightDoubleMonth}px !important;
    }
  `;
      setPickerHeight(heightDoubleMonth);
    },
    [singleMonth, transitionContainerStylesheet, withTimePicker]
  );

  const handleDatesChange = useCallback(
    ({ startDate, endDate }: { startDate: Moment | null; endDate: Moment | null }) => {
      const getDateRangeItem = (date: Moment | null, position: 'start' | 'end') => {
        if (date === null) {
          return date;
        }

        return date
          .set({
            hour: timePickerValues[position].hour,
            minute: timePickerValues[position].minute,
          })
          .toDate();
      };
      const newDateRange: DateRange = [getDateRangeItem(startDate, 'start'), getDateRangeItem(endDate, 'end')];

      setDateRange(newDateRange);
      onDateRangeChange(newDateRange);
    },
    [timePickerValues, onDateRangeChange]
  );

  const handleTimePresets = useCallback(
    ({ startDate, endDate }: { startDate: DateTime; endDate: DateTime }) => {
      const shouldRedrawMonth = startDate.month - 1 !== dateRange[0]?.getMonth();
      if (shouldRedrawMonth) {
        setFocusedInput(null);
      }
      const newDateRange: DateRange = [startDate.toJSDate(), endDate.toJSDate()];
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      const isSameRage = (oldRange: DateRange, newRange: DateRange) => {
        return (
          JSON.stringify(
            oldRange.map((d) => d?.toLocaleDateString('en-US', options as DateTimeFormatOptions) ?? null)
          ) ===
          JSON.stringify(newRange.map((d) => d?.toLocaleDateString('en-US', options as DateTimeFormatOptions) ?? null))
        );
      };

      if (isSameRage(dateRange, newDateRange)) {
        return;
      }

      setDateRange(newDateRange);
      setShouldUpdateMonths(true);

      setTimeout(() => {
        onDateRangeChange(newDateRange);
        setFocusedInput('startDate');
      });
    },
    [dateRange, onDateRangeChange]
  );

  const updateDateTime = useCallback(
    (field: 'start' | 'end', dateRangeValue?: Date | null, timePickerValue?: Date | null) => {
      if (isNil(dateRangeValue) || isNil(timePickerValue)) {
        return;
      }
      const hours = timePickerValue.getHours();
      const minutes = timePickerValue.getMinutes();

      const getNewRange = (prevRange = dateRange) =>
        prevRange.map((date, idx) => {
          const shouldBeChanged = (field === 'start' && idx === 0) || (field === 'end' && idx === 1);
          if (!shouldBeChanged || isNil(prevRange[idx])) {
            return date;
          }
          const newDate = new Date(prevRange[idx] as Date);
          newDate?.setHours?.(hours, minutes);
          return newDate;
        }) as DateRange;

      onDateRangeChange(getNewRange());
    },
    [dateRange, onDateRangeChange]
  );

  const handleTimePickerChange = useCallback(
    (key: 'start' | 'end') => (value: DateTime | null) => {
      if (isNil(value) || !value.isValid) {
        return;
      }
      setTimePickerValues((prevValues) => ({ ...prevValues, [key]: value }));
      updateDateTime(key, dateRange[key === 'start' ? 0 : 1], value?.toJSDate?.());
    },
    [dateRange, updateDateTime]
  );
  const handleAcceptTimePickerChange = useCallback(() => {
    const { start, end } = timePickerValues;
    updateDateTime('start', dateRange[0], start.toJSDate());
    updateDateTime('end', dateRange[1], end.toJSDate());
  }, [dateRange, timePickerValues, updateDateTime]);

  const handleClose = useCallback(
    (date: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
      changeMonthHandler(date.startDate || moment());
      if (onClose) {
        onClose(date);
        return;
      }
      handleAcceptTimePickerChange();
    },
    [changeMonthHandler, handleAcceptTimePickerChange, onClose]
  );

  const handleFocusChange = useCallback(
    (newFocus: FocusedInputShape | null) => {
      const isTimePickerOpen = openAdditionalPicker.position && openAdditionalPicker.open;
      if (isTimePickerOpen) {
        return;
      }

      if (isNil(newFocus) && (openAdditionalPicker.position || openAdditionalPicker.open)) {
        if (!openAdditionalPicker.open) {
          setFocusedInput(openAdditionalPicker.position);
          setOpenAdditionalPicker({ open: false, position: null });
        }
        return;
      }

      setOpenAdditionalPicker({ open: false, position: null });

      setFocusedInput(newFocus);
    },
    [openAdditionalPicker]
  );

  const startDate = getStartOrEndDate(dateRange[0]);
  const endDate = getStartOrEndDate(dateRange[1]);

  useEffect(
    function UpdateHeightAfterTimeSelect() {
      changeMonthHandler(startDate || moment());
    },
    // Effect only once after we use time presets and change month by code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldUpdateMonths]
  );

  useEffect(function InitialHeightSet() {
    changeMonthHandler(startDate || moment());
    // Effect only once when rendering picker
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenTimePicker = (position: FocusedInputShape) => () => setOpenAdditionalPicker({ open: true, position });

  const handleCloseTimePicker = () => setOpenAdditionalPicker({ open: false, position: null });

  const timeInputs = (
    <TimePickerWrapper $singleMonth={singleMonth}>
      <TimePickerStyled
        value={timePickerValues.start}
        views={['hours', 'minutes']}
        ampm={false}
        onChange={handleTimePickerChange('start')}
        onOpen={handleOpenTimePicker('startDate')}
        onClose={handleCloseTimePicker}
        timeSteps={{ minutes: 1 }}
        closeOnSelect={false}
      />
      <TimePickerStyled
        value={timePickerValues.end}
        views={['hours', 'minutes']}
        ampm={false}
        onChange={handleTimePickerChange('end')}
        onOpen={handleOpenTimePicker('endDate')}
        onClose={handleCloseTimePicker}
        timeSteps={{ minutes: 1 }}
        closeOnSelect={false}
      />
    </TimePickerWrapper>
  );

  const renderCalendarInfo = (
    <Box>
      {withTimePicker && timeInputs}
      {withTimePresets && <TimePresets pickerHeight={pickerHeight} onChange={handleTimePresets} />}
    </Box>
  );

  return (
    <DateRangePickerContainer $size={size} $error={error} $openDirection={openDirection}>
      {label !== undefined && (
        <DateRangeLabel error={error} $isMaterialStyled={isMaterialStyled}>
          {label}
        </DateRangeLabel>
      )}
      <ReactDatesPicker
        {...rest}
        disabled={disabled}
        startDate={startDate}
        startDateId={startDateId}
        startDatePlaceholderText='Start Date'
        endDate={endDate}
        endDateId={endDateId}
        endDatePlaceholderText='End Date'
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        small={true}
        isOutsideRange={() => false}
        showDefaultInputIcon={true}
        anchorDirection={anchorDirection}
        hideKeyboardShortcutsPanel={true}
        numberOfMonths={singleMonth ? 1 : 2}
        minDate={moment(minDateTime)}
        maxDate={moment(maxDateTime)}
        openDirection={openDirection}
        onClose={handleClose}
        minimumNights={0}
        renderCalendarInfo={() => renderCalendarInfo}
        calendarInfoPosition={'before'}
        displayFormat={handleFormat}
        keepOpenOnDateSelect={true}
        onNextMonthClick={changeMonthHandler}
        onPrevMonthClick={changeMonthHandler}
        appendToBody={renderInModal}
        renderMonthText={undefined}
        renderMonthElement={(properties) => <MonthYearPicker focusHandler={setOpenAdditionalPicker} {...properties} />}
      />
    </DateRangePickerContainer>
  );
};
