/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any */
import { FC, forwardRef, SyntheticEvent } from 'react';
import { Typography } from '@mui/material';

import { Environment } from '@playq/octopus-common';

import { IEnvironmentStatusProps } from '../types';

import { EnvLabel, StatusButton } from './styles';

export const EnvironmentStatus: FC<IEnvironmentStatusProps> = forwardRef((props: IEnvironmentStatusProps, ref) => {
  const { envs, id, onClick } = props;

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  const inSandbox = envs.sandbox.includes(id);
  const inLive = envs.live.includes(id);

  return (
    <StatusButton onClick={handleClick} data-testid='button-status' ref={ref as any}>
      {inSandbox ? (
        <EnvLabel env={Environment.Sandbox} clickable>
          {Environment.Sandbox}
        </EnvLabel>
      ) : (
        <EnvLabel clickable>
          <Typography variant='inherit' color='textSecondary'>
            <span>Sandbox</span>
          </Typography>
        </EnvLabel>
      )}
      {inLive ? (
        <EnvLabel env={Environment.Live} clickable>
          {Environment.Live}
        </EnvLabel>
      ) : (
        <EnvLabel clickable>
          <Typography variant='inherit' color='textSecondary'>
            <span>Live</span>
          </Typography>
        </EnvLabel>
      )}
    </StatusButton>
  );
});
