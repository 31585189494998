import { ChangeEvent, FC, FocusEvent, memo, useState } from 'react';
import { styled, TextField, TextFieldProps, useEventCallback } from '@mui/material';

import { useEnableBodyScroll } from '/common/useEnableBodyScroll';

const TextFieldStyled = styled(TextField)<{ $helperTextPosition: 'block' | 'absolute' }>`
  position: relative;

  ${({ $helperTextPosition }) =>
    $helperTextPosition === 'absolute' &&
    `
   & .MuiFormHelperText-root {
    position: absolute;
    bottom: -17px;
  }
  `}
`;

export const FormField: FC<
  Omit<TextFieldProps, 'error'> & {
    error?: string | boolean;
    helperTextPosition?: 'block' | 'absolute';
  }
> = memo((props) => {
  const [touched, setTouched] = useState<boolean>(false);

  const { enableBodyScroll, disableBodyScroll } = useEnableBodyScroll(props.type === 'number');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    props.onChange?.(e);
  };

  const handleFocus = useEventCallback((e: FocusEvent<HTMLInputElement>) => {
    disableBodyScroll();
    props.onFocus?.(e);
  });

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    enableBodyScroll();
    props.onBlur?.(e);
  };

  const helperText = touched && typeof props.error === 'string' ? props.error : props.helperText;

  return (
    <TextFieldStyled
      {...props}
      $helperTextPosition={props.helperTextPosition ?? 'absolute'}
      error={touched && (typeof props.error === 'boolean' ? props.error : props.error !== undefined)}
      helperText={helperText}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
});
