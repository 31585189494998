import { Box, styled } from '@mui/material';

export const ThumbnailPreviewContainerStyled = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: inherit;
  max-width: inherit;
  cursor: pointer;
`;

export const FilePreviewContainerStyled = styled(Box)`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-height: inherit;
  max-width: inherit;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const TooltipContainerStyled = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
