import { useEffect, useState } from 'react';

import { Mimes } from '/constants/file';
import { Loading } from '/common/withLoading';

import { IPreviewComponentProps } from '../types';

import { ImageStyled } from './styles';

export function ImagePreview({ url, Fallback, spinnersize, onRebuildThumbnail: _, ...rest }: IPreviewComponentProps) {
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(true);
  const handleError = () => setError(true);

  const handleLoaded = () => {
    setLoading(false);
  };
  const handleLoadStart = () => {
    setLoading(true);
  };
  useEffect(() => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      setLoading(false);
    } else {
      img.onload = () => setLoading(false);
      img.onerror = () => setError(true);
    }
  }, [url]);

  return error ? (
    <Fallback fallbackTitle='Image is invalid' {...rest} />
  ) : (
    <Loading isLoading={loading} size={spinnersize as number} alignItems='center'>
      <ImageStyled
        style={{ display: loading ? 'none' : 'block' }}
        src={url}
        onLoadStart={handleLoadStart}
        onLoad={handleLoaded}
        alt={error ? 'failure' : 'image'}
        {...rest}
        onError={handleError}
      />
    </Loading>
  );
}

ImagePreview.mimes = Mimes.Image;
