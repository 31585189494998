import { HTMLAttributes, ReactNode } from 'react';

import { Environment } from '@playq/octopus-common';

export enum LabelThemedColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Grey = 'grey',
  Orange = 'orange',
  Copy = 'success',
  Error = 'error',
}

export interface ILabelThemedProps extends HTMLAttributes<HTMLSpanElement> {
  env?: Environment;
  children: ReactNode;
  className?: string;
  color?: LabelThemedColor;
  clickable?: boolean;
  outline?: boolean;
  withoutMargins?: { right?: boolean; left?: boolean };
}
