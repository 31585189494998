import { Box, css, InputLabel, List, styled, Theme } from '@mui/material';
import { MonthCalendar, YearCalendar } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { grey } from '@mui/material/colors';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { ThemeMode } from '/common/models';
import { ThemeProvider } from '/common';

import { UseDateRangePickerStylesArgs } from './types';

export const MEDIUM_DATE_RANGE_PICKER_HEIGHT = 46;
export const LARGE_DATE_RANGE_PICKER_HEIGHT = 56;
export const TIME_PRESETS_WIDTH = 150;

const getInputHeightDepengingOnSize = (size: UseDateRangePickerStylesArgs['size']) => {
  if (size === 'small') {
    return 'inherit';
  }
  if (size === 'medium') {
    return `${MEDIUM_DATE_RANGE_PICKER_HEIGHT}px`;
  }
  if (size === 'large') {
    return `${LARGE_DATE_RANGE_PICKER_HEIGHT}px`;
  }
};

const overridePrimaryLightMode = 'rgba(25, 118, 210, 0.12)';
const overridePrimaryDarkMode = 'rgba(144, 202, 249, 0.12)';

const borderColor = grey[400];
const disabledOpacity = (theme: Theme) => theme.palette.action.disabledOpacity;

const getColorDependingOnError = (theme: Theme, error: UseDateRangePickerStylesArgs['error'], defaultColor?: string) =>
  error ? theme.palette.error.main : (defaultColor ?? theme.palette.text.primary);

const overridePrimaryLight = (theme: Theme) =>
  ThemeProvider.getAppropriateStyles(
    theme.palette.mode as ThemeMode,
    overridePrimaryLightMode,
    overridePrimaryDarkMode
  );

export const TimePickerStyled = styled(TimePicker<DateTime>)`
  & .MuiInputBase-input {
    height: 8px;
    width: 85px;
  }
`;

export const TimePickerWrapper = styled(Box)<{ $singleMonth?: boolean }>`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
  justify-content: space-around;
  padding: ${({ theme }) => theme.spacing(3)};
  padding-top: 0;
  width: ${({ $singleMonth }) => `calc(100% - ${$singleMonth ? 0 : TIME_PRESETS_WIDTH}px)`};
`;

export const DateRangeLabel = styled(InputLabel)<{
  $isMaterialStyled?: UseDateRangePickerStylesArgs['isMaterialStyled'];
  $error?: UseDateRangePickerStylesArgs['error'];
}>`
  ${({ theme, $isMaterialStyled, $error }) => css`
    ${$isMaterialStyled &&
    css`
      padding: 0 5px;
      text-align: center;
      position: absolute;
      top: -6px;
      left: 6px;
      height: 14px;
      line-height: 12px;
      font-size: 12px;
      pointer-events: none;
      z-index: 1;
      background-color: ${theme.palette.background.paper};
    `}

    ${$error &&
    css`
      color: ${theme.palette.error.main};
    `}
  `}
`;

export const DateRangePickerContainer = styled(Box)<{
  $error: UseDateRangePickerStylesArgs['error'];
  $size: UseDateRangePickerStylesArgs['size'];
  $openDirection: UseDateRangePickerStylesArgs['openDirection'];
}>`
  position: relative;

  &.unique_date_range_picker {
    position: static;
  }

  & .DateRangePicker,
  & .DateRangePickerInput {
    height: ${({ $size }) => getInputHeightDepengingOnSize($size)};
    width: 100%;
    border-width: ${({ $error }) => ($error ? 1.2 : 1)}px;
    border-color: ${({ theme, $error }) => getColorDependingOnError(theme, $error, borderColor)};
  }

  & .DateRangePickerInput {
    width: fit-content;
    display: flex;
    align-items: center;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    padding-right: ${({ theme }) => theme.spacing(2)};
    background: ${({ theme }) => theme.palette.background.paper};
  }

  & .DateInput,
  & .CalendarMonthGrid,
  & .CalendarMonth,
  & .CalendarMonth_caption,
  & .DayPickerNavigation_button,
  & .CalendarDay {
    background: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.text.primary};
  }

  & .DateInput {
    width: fit-content;
  }

  & .DateRangePickerInput_calendarIcon_svg {
    fill: ${({ theme, $error }) => getColorDependingOnError(theme, $error, borderColor)};
  }

  & .DateRangePickerInput_calendarIcon {
    margin-right: 0;
  }

  & .DateInput_input {
    background: ${({ theme }) => theme.palette.background.paper};
    min-width: 100px;
    color: ${({ theme, $error }) => getColorDependingOnError(theme, $error)};
    outline: none;
  }

  & .DateInput_input:disabled {
    opacity: ${({ theme }) => disabledOpacity(theme)};
  }

  & .DateInput_input__focused {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  & .DateInput_fang {
    z-index: ${({ theme }) => theme.zIndex.modal + 1};
    display: none;
  }

  & .DayPickerNavigation_button__default,
  .DayPickerNavigation_button__default:hover,
  .DayPickerNavigation_button__default:focus {
    border: none;
    top: 20px;
  }

  & .DayPickerNavigation_button__default:active {
    background: ${({ theme }) => theme.palette.background.default};
  }

  & .DayPickerNavigation_button__default:hover .DayPickerNavigation_svg__horizontal {
    fill: ${({ theme }) => theme.palette.primary.light};
  }

  & .DateInput_fangShape {
    fill: ${({ theme }) => theme.palette.background.paper};
  }

  & .DateInput_fangStroke {
    stroke: ${({ theme }) => theme.palette.divider};
  }

  & .DateRangePickerInput_arrow {
    color: ${({ theme, $error }) => getColorDependingOnError(theme, $error, borderColor)};
  }

  & .DateRangePicker_picker {
    margin-top: ${({ $size }) => ($size === 'medium' ? 11 : 2)}px;
    margin-bottom: ${({ $openDirection, $size }) => ($openDirection === 'up' && $size === 'medium' ? 11 : 2)}px;
    background: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.text.primary};
    z-index: ${({ theme }) => theme.zIndex.drawer + 1};
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
    rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  }

  & .DayPicker {
    background: ${({ theme }) => theme.palette.background.paper};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }

  & .CalendarMonth_table {
    border-collapse: separate;
    border-spacing: 0 2px;
  }

  & .CalendarDay {
    border: none;
    padding: 1px;
  }

  & .CalendarDay__selected,
  & .CalendarDay__selected_span,
  & .CalendarDay:hover {
    border-color: ${({ theme }) => theme.palette.primary.contrastText};
    background: ${({ theme }) => theme.palette.background.paper};
  }

  & .CalendarDay__selected {
    border-radius: 50%;
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  & tr > .CalendarDay__selected_span:last-of-type, .CalendarDay__lastDayOfWeek.CalendarDay__hovered_span {
    border-radius: 0 50% 50% 0;
  }


  & .CalendarDay__selected_span:first-of-type, .CalendarDay__firstDayOfWeek.CalendarDay__hovered_span {
    border-radius: 50% 0 0 50%;
  }

  & td:empty + td {
    border-radius: 50% 0 0 50%;
  }

  & td:empty + .CalendarDay__selected_end {
    border-radius: 50%;

    &:after {
      display: none;
    }
  }

  & tr:last-child > .CalendarDay__selected_span {
    position: relative;
    z-index: 2;
  }

  & tr:last-child > .CalendarDay__selected_span + td:empty {
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 100%;
      border-radius: 0 55px 55px 0;
      background: ${({ theme }) => overridePrimaryLight(theme)};
    }
  }


  & tr:last-child > td:empty + td {
    position: relative;
    z-index: 1;

    &:after {
      display: none;
    }

    &:before {
      display: none;
    }
  }

  & .CalendarDay__selected_start {
    border-radius: 50%;
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      z-index: -2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50% 0 0 50%;
      background: ${({ theme }) => overridePrimaryLight(theme)};
    }


    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & .CalendarDay__selected_end, & .CalendarDay__hovered_span:hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: ${({ theme }) => theme.palette.primary.main};
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      z-index: -2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0 50% 50% 0;
      background: ${({ theme }) => overridePrimaryLight(theme)};
    }

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & .CalendarDay__selected_end, & .CalendarDay__hovered_span.CalendarDay__hovered_span:hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: ${({ theme }) => theme.palette.primary.main};
    position: relative;
    z-index: 1;
    border-radius: 50%;

    &:after {
      content: '';
      z-index: -2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & .CalendarDay__no_selected_start_before_selected_end.CalendarDay__hovered_span.CalendarDay__hovered_span:hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: ${({ theme }) => theme.palette.primary.main};
    position: relative;
    z-index: 1;
    border-radius: 50%;

    &:after {
      content: '';
      z-index: -2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 50% 0 0 50%;
      height: 100%;
    }

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: ${({ theme }) => theme.palette.primary.main};
    }
  }


  & .CalendarDay__selected_start.CalendarDay__selected_end, .CalendarDay__selected_start_no_selected_end:hover {
    &:after {
      content: '';
      display: none;
    }
  }

  & .CalendarDay__lastDayOfWeek.CalendarDay__selected_start, .CalendarDay__firstDayOfWeek.CalendarDay__selected_end {
    background: ${({ theme }) => overridePrimaryLight(theme)};
    border-radius: 50%;
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      display: none;
    }


    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & .CalendarDay__today {
    text-decoration: underline;
  }

  & .CalendarDay__selected_span {
    background: ${({ theme }) => overridePrimaryLight(theme)};
    border-radius: 0;
  }

  & .CalendarDay__hovered_span {
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => overridePrimaryLight(theme)};
    border-radius: 0;
  }

  & .CalendarDay:hover {
    background: ${({ theme }) => theme.palette.background.paper};
    border: none;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px dashed ${({ theme }) => theme.palette.action.disabled};
      background: none;
    }
  }

  & .CalendarDay__selected_span:hover, &.CalendarDay__selected_span:hover {
    background: ${({ theme }) => overridePrimaryLight(theme)};
    border: none;
    position: relative;
    z-index: 1;
    color: ${({ theme }) => theme.palette.text.primary};

    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: ${({ theme }) => overridePrimaryLight(theme)};
    }


    &:before {
      content: '';
      display: none;
    }
  }


  & .CalendarDay.CalendarDay__selected_start:hover, &.CalendarDay__selected_start_in_hovered_span:hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: ${({ theme }) => overridePrimaryLight(theme)};
    position: relative;
    border-radius: 50%;
    z-index: 1;

    &:after {
      content: '';
      z-index: -2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50% 0 0 50%;
      background: ${({ theme }) => overridePrimaryLight(theme)};
    }

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: ${({ theme }) => theme.palette.primary.main};
      border: none;
      opacity: 0.5;
    }
  }

  & .CalendarDay.CalendarDay__selected_end:hover, &.CalendarDay__selected_end_in_hovered_span:hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: ${({ theme }) => overridePrimaryLight(theme)};
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      z-index: -2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0 50% 50% 0;
      background: ${({ theme }) => overridePrimaryLight(theme)};
    }

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: ${({ theme }) => theme.palette.primary.main};
      border: none;
      opacity: 0.5;
    }
  }
}
`;

export const StyledMonthCalendar = styled(MonthCalendar<DateTime>)`
  width: 250px;
`;

export const StyledYearCalendar = styled(YearCalendar<DateTime>)`
  width: 180px;
  max-height: 250px;

  .MuiPickersYear-root {
    flex-basis: 50%;
  }
`;

export const BasicWrapper = styled('div')`
  display: flex;
  justify-content: center;
`;

export const CalendarWrapper = styled('div')`
  display: flex;
  justify-content: center;
  max-height: 250px;
`;

export const TimePresetsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;

  ${() => `
     width: ${`${TIME_PRESETS_WIDTH}px`};
  `}
`;

export const ListStyledWrapper = styled(List)<{ $height: number }>`
  width: 100%;

  ${(props) => `
     height: ${`${props.$height}px`};

  `}
`;
