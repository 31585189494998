import { Box, createTheme, css, Divider, InputBase, Paper, styled, Typography } from '@mui/material';
import { IWithCSS } from 'common/models';

import { getOppositeColor } from '/helpers/color';
import { headerHeight } from '/common/Header/styles';
import { APP_CONTENT_PADDING_PX } from '/common/AppPage/styles';

const versionPickerColor = '#ff9800';

export const StickyContainer = styled('div')`
  position: sticky;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  top: ${headerHeight}px;
  padding: ${APP_CONTENT_PADDING_PX}px;
  margin: ${-APP_CONTENT_PADDING_PX}px ${-APP_CONTENT_PADDING_PX}px 0;
  background: ${({ theme }) => theme.palette.background.default};
  width: calc(100% + ${2 * APP_CONTENT_PADDING_PX}px);
`;

export const ControlBarRoot = styled(Paper)`
  width: 100%;
  overflow: hidden;
  padding: 2px 4px 2px 15px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const ControlBarWrapper = styled('div')`
  display: flex;
  align-items: center;
  min-height: 64px;
  height: auto;
  width: 100%;
`;

export const ControlBarInput = styled(InputBase)`
  text-align: right;
  margin-left: 8px;
  min-width: 200px;
`;

export const LogData = styled('div')`
  flex: 1;
  min-width: 0;
  margin-right: 10px;
`;

export const LogDataInfoRow = styled(Box)`
  display: inline-flex;
  vertical-align: middle;
  font-size: 12px;
  width: 100%;

  & svg {
    margin-right: 5px;
  }
`;

export const ControlBarDivider = styled(Divider)`
  width: 1px;
  height: 28px;
  margin: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(1)}`};
`;

export const ErrorMessage = styled(Typography)`
  position: absolute;
  bottom: 10px;
  font-size: 12px;
`;

export const LogDataInfo = styled('div')<{ noComment: boolean }>`
  white-space: nowrap;
  line-height: 0;

  & span:first-of-type {
    line-height: 1.6;
  }

  ${({ noComment }) =>
    noComment &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & span:first-of-type {
        margin-bottom: 5px;
      }
    `};
`;

export const pickerButtonHighlightedCSS = css`
  background-color: ${({ theme }) => `${theme.palette.warning.dark} !important`};
`;

export const versionPickerTheme = createTheme({
  palette: {
    primary: {
      main: versionPickerColor,
      contrastText: getOppositeColor(versionPickerColor),
    },
  },
});

export const tooltipCSS = css`
  & .MuiTooltip-tooltip {
    margin-top: 4px !important;
  }
`;

export const ContentStyled = styled(Box)<IWithCSS>`
  margin: '0 5px' ${({ css }) => css};
`;
