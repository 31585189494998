import { FC, SyntheticEvent, useState } from 'react';
import { Tooltip } from '@mui/material';

import { Environment } from '@playq/octopus-common';

import { PromotionConfirmDialog } from '/shared/PromotionConfirmDialog';

import { IEnvironmentFormProps } from '../types';

import { DotsLoading, EnvCheckbox, FormWrapper, LiveLabel, SandboxLabel } from './styles';

export const EnvironmentForm: FC<IEnvironmentFormProps> = (props) => {
  const { id, envs, envProcessing, disabled, onPromote, onDemote, extraInfo } = props;

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [statusData, setStatusData] = useState({
    env: Environment.Sandbox,
    status: false,
    setEnvStatus,
    extraInfo,
  });

  const handleConfirmDialogOpen = (value: boolean) => {
    setConfirmDialogOpen(value);
  };

  const isSandbox = envs.sandbox.includes(id);
  const isLive = envs.live.includes(id);

  function setEnvStatus(env: Environment, status: boolean): void {
    if (status) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (onDemote) {
        onDemote(env);
      }
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    } else if (onPromote) {
      onPromote(env);
    }
  }

  const toggleEnv = (env: Environment, status: boolean) => () => {
    setStatusData({ env, status, setEnvStatus, extraInfo });
    handleConfirmDialogOpen(true);
  };

  const isEnvDisabled = (env: Environment): boolean => {
    const envDisabled = disabled ? disabled[env] : false;
    return (envProcessing.includes(env) || envDisabled) as boolean;
  };

  const isLiveDisabled = !isSandbox && !isLive;

  const handleStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const formContent = (
    <>
      <SandboxLabel>
        {Environment.Sandbox}
        {envProcessing.includes(Environment.Sandbox) && <DotsLoading />}
      </SandboxLabel>
      <EnvCheckbox
        checked={isSandbox}
        onClick={toggleEnv(Environment.Sandbox, isSandbox)}
        disabled={isEnvDisabled(Environment.Sandbox)}
        type={Environment.Sandbox}
        data-testid='switch-sandbox'
      />
      <LiveLabel>
        {Environment.Live}
        {envProcessing.includes(Environment.Live) && <DotsLoading />}
      </LiveLabel>
      <Tooltip title={isLiveDisabled ? 'Should be promoted on Sandbox' : ''}>
        <EnvCheckbox
          checked={isLive}
          onClick={toggleEnv(Environment.Live, isLive)}
          disabled={isEnvDisabled(Environment.Live) || isLiveDisabled}
          type={Environment.Live}
          data-testid='switch-live'
        />
      </Tooltip>
    </>
  );

  return (
    <div onClick={handleStopPropagation}>
      <FormWrapper>{formContent}</FormWrapper>
      {confirmDialogOpen && (
        <PromotionConfirmDialog statusData={statusData} closeDialog={() => handleConfirmDialogOpen(false)} />
      )}
    </div>
  );
};
